// 遗产使用功能图
const selfData = {
    snTable: {
        table: {
            data: [],
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'WDMC',
                label: '变化时间',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'LJ',
                label: '情况说明',
            }, {
                prop: 'LJ',
                label: '评估',
            }, {
                prop: 'LJ',
                label: '对比基准',
            }],
        },
    },
};
export default selfData;